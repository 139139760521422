import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UtilService } from '../../../../core/services/util.service';

@Component({
  selector: 'app-snack-notification',
  templateUrl: './snack-notification.component.html',
  styleUrl: './snack-notification.component.scss'
})
export class SnackNotificationComponent {
  constructor(
    public _util: UtilService,
    public snackBarRef: MatSnackBarRef<SnackNotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private el: ElementRef
    ) {}

    isHover: boolean= false;
    @ViewChild('snackNotification') snackNotification!: ElementRef;
    @HostListener('mouseenter', ['$event.target'])
    onMouseEnter(target: any) {
      if(this.el.nativeElement.contains(target)) {
        this.isHover = true;
        this.closeSnack(1000000);
      }
    }

    @HostListener('mouseleave', ['$event.target'])
    onMouseLeave(target: any) {
      if(this.el.nativeElement.contains(target)) {
        this.closeSnack(0);
      }
    }
  
  ngOnInit() {
    this.closeSnack(3000);
  }

  getNotificationIcon() {
    switch(this.data.action) {
      case 'success': {
        return 'assets/icons/green-clipboard.svg'
      }
      case 'error': {
        return 'assets/icons/red-clipboard.svg'
      }
      case 'notif': {
        return 'assets/icons/bell.svg'
      }
    }
    return;
  }

  closeSnack(duration: any) {
    this.snackBarRef._open();
    this.snackBarRef._dismissAfter(duration);
  }
}
