
<div class="drag-photo">
    <div class="uploader-wrapper" [ngClass]="{'error-file-uploader': error_condition}">
  <ngx-awesome-uploader
    #uploader
    [enableCropper]="cropper"
    [showeDragDropZone]="DragDropZone" 
    [uploadType]="'multi'"
    [fileMaxSize]="fileMaxSize"
    [fileMaxCount]="fileMaxCount"
    [totalMaxSize]="10000"
    [accept]="accept"
    [fileExtensions]="fileExtensions"
    [cropperOptions]="cropperOptions"
    [showPreviewContainer]="showPreview"
    [captions]="captions"
    [enableAutoUpload]="autoUpload"
    [showPreviewContainer]="false"
    (validationError)="onValidationError($event)"
    (uploadSuccess)="onUploadSuccess($event)"
    (uploadFail)="onFileUploadFail($event)"
    (fileAdded)="onFileAdded($event)"
    (removeSuccess)="onRemoveSuccess($event)"
    (fileRemoved)="onFileRemove($event)"

  >
  </ngx-awesome-uploader>
  <!-- [adapter]="adapter" -->
  </div>
  </div>