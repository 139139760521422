import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
// import { event } from 'jquery';
import { FilePickerAdapter, FilePickerComponent, FilePreviewModel, UploaderCaptions, ValidationError } from 'ngx-awesome-uploader';
import { Observable, delay, of } from 'rxjs';
import { UtilService } from '../../../../core/services/util.service';

@Component({
  selector: 'app-fileuploder',
  templateUrl: './fileuploder.component.html',
  styleUrl: './fileuploder.component.scss'
})
export class FileuploderComponent {
  @Input() cropper: boolean = true; /** Whether to enable cropper*/
  @Input() DragDropZone: boolean = true; /** Whether to show default drag and drop template */
  @Input() type: string = 'multi';/** Single or multiple. Default: multi */
  @Input() fileMaxSize: number = 2;/** Max size of selected file in MB. Default: no limit */
  @Input() fileMaxCount: number = 10;/** Max count of file in multi-upload. Default: no limit */
  @Input() totalMaxSize!: number/** Total Max size limit of all files in MB. Default: no limit */
  @Input() accept: string = '.doc, .docm, .docx, .jpg, .png,.pdf,.jpeg';/** Which file types to show on choose file dialog. Default: show all */
  @Input() fileExtensions: string[] = ['doc', 'docm', 'docx', 'pdf', 'jpg', 'jpeg', 'png'];/** File extensions filter. Default: any exteion */
  @Input() error_condition: boolean = false // for displaying error properties based on condition

  /** Cropper options if cropper enabled. Default:
dragMode: 'crop',
aspectRatio: 1,
autoCrop: true,
movable: true,
zoomable: true,
scalable: true,
autoCropArea: 0.8
*/
  @Input() cropperOptions: Object;
  @Input() adapter!: FilePickerAdapter; /** Custom Adapter for uploading/removing files. Required */
  @Input() dropzoneTemplate: TemplateRef<any> | undefined;  /** Custom template for dropzone. Optional */
  @Input() itemTemplate: TemplateRef<any> | undefined;/** Custom Preview Item template. Optional */
  @Input() showPreview: boolean = true;/** Whether to show default files preview container. Default: true */
  @Input() customValidator!: (file: File) => Observable<boolean>;/** Custom validator function. Optional */
  @Input() captions: UploaderCaptions;/** Custom captions input. Used for multi language support */
  @Input() postApiUrl: any; //passing your post Ai url
  @Input() deleteApiUrl: any;//passing you delete api url
  @Input() autoUpload = true;/** Whether to auto upload file on file choose or not. Default: true. You can get files list by accessing component files. */

  @Output() validationError = new EventEmitter<any>();
  @Output() uploadSuccess = new EventEmitter<any>();
  @Output() removeSuccess = new EventEmitter<any>();
  @Output() fileAdd = new EventEmitter<any>();
  @Output() uploadFile = new EventEmitter<any>();
  @Output() removeFile = new EventEmitter();

  @ViewChild ('uploader', {static: false}) uploader!:  FilePickerComponent;

  constructor(
    private http: HttpClient,
    private _util: UtilService
    ) {

    //cropper width and height
    this.cropperOptions = {
      minContainerWidth: '500',
      minContainerHeight: '500',
    };

    this.captions = {
      dropzone: {
        title: '',
        or: '',
        browse: 'Browse Files',
      },
      cropper: {
        crop: 'Crop',
        cancel: 'Cancel',
      },
      previewCard: {
        remove: 'Remove',
        uploadError: 'Please upload a valid file',
      },
    };
  }


  ngOnInit(): void {
    // this.adapter = new DemoFilePickerAdapter(this.http, this.postApiUrl, this.deleteApiUrl);
  }

  /** Emitted on file validation fail */
  onValidationError(error: ValidationError): void {
    switch(error?.error) {
      case 'FILE_MAX_SIZE': {
        this._util.snackNotification('error', `Please ensure that file size does not exceed ${this.fileMaxSize}mb!` );
        break;
      }
      case 'FILE_MAX_COUNT': {
        this._util.snackNotification('error',  `You cannot upload more than ${this.fileMaxCount} file!` );
        break;
      }
    }
    
    this.validationError.emit(error)
  }

  /** Emitted when file upload via api success.
  Emitted for every file */
  onUploadSuccess(event: any): void {
    this.uploadSuccess.emit(event)
    // this.listenForDeleteFile();
  }

  /** Emitted when file is removed via api successfully.
  Emitted for every file */
  onRemoveSuccess(event: FilePreviewModel) {
    this.removeSuccess.emit(event)
  }


  /** Emitted when file is added and passed validations. Not uploaded yet */
  onFileAdded(event: any) {    
    // this.listenForDeleteFile();
    this.fileAdd.emit(event)
  }

  listenForDeleteFile() {
    const file = document.querySelectorAll('file-preview-item');
    const file_remove = document.querySelectorAll('.ngx-close-icon-wrapper');
    file_remove.forEach((element, index) => {
      (element as any).removeAllListeners('click');
      if(element) {
        element.addEventListener('click', () => {
          file[index].remove();
          element.remove();
          this.deleteFile(index);
        });
      }
    });
  }

  deleteFile(index: number) {
    this.removeFile.emit(index);
    if(this.fileMaxCount == 1) {
      this.uploader.removeFileFromList(this.uploader.files[index])
    }
    this.listenForDeleteFile();
  }

  /** Emitted when file upload via api fails.
  Emitted for every file */
  onFileUploadFail(event: any) {
    event.preventDefault();
    this.uploadFile.emit(event)
  }

  /** Emitted when file is removed from fileList */
  onFileRemove(event: any) {
    this.removeFile.emit(event)
  }
}
