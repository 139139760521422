import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-preview-popup',
  templateUrl: './file-preview-popup.component.html',
  styleUrl: './file-preview-popup.component.scss',
  animations: [
    trigger('flyInOut', [
      transition('* => next', [
        style({ transform: 'translateX(100%)' }), // right to left
        animate('300ms', style({ transform: 'translateX(0)' })),
      ]),
      transition('* => previous', [
        style({ transform: 'translateX(-100%)' }), // left to right
        animate('300ms', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class FilePreviewPopupComponent implements OnInit {

  @Input() docUrl!: any;
  docs: any;
  viewerType: any;
  isDoc: boolean = false;
  activeIndex: any;
  action: any;
  isDocLoading: boolean = false;

  constructor(
    @Optional() public _dialog: MatDialogRef<FilePreviewPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private _matData: {docData: any}
  ) {}

  ngOnInit(): void {
    this.docs = this._matData.docData.files;
    this.activeIndex = this._matData.docData.index;
  }

  getViewerType(doc: any) {
    if(doc.file_name.includes(".xls") || doc.file_name.includes(".xlsx") || doc.file_name.includes(".ppt") || doc.file_name.includes(".pptx") || doc.file_name.includes(".docx")) {
      return "office";
    }
    else if(doc.file_name.includes(".pdf")){
      return "pdf";
    }
    return "google";
  }

  updateActiveIndex(action: any) {
    if(action === 'next' && this.activeIndex < this.docs.length - 1) {
      this.activeIndex++;
    }
    else if(action === 'previous' && this.activeIndex > 0) {
      this.activeIndex--;
    }
    this.action = action;
    this.isDocLoading = true;
  }

  closePopup() {
    this._dialog.close();
  }

}
