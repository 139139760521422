import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SnackNotificationComponent } from '../../modules/common/reusable-components/snack-notification/snack-notification.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  sidebarListIndex = new BehaviorSubject<number>(0);
  private activeListIndex = new BehaviorSubject<number>(0);
  private previousIndex = new BehaviorSubject<number>(0);
  sidebarMaxIndex: number = 0;
  activeListItemIndex: number = 0;

  constructor(
    private _snackBar: MatSnackBar

  ) { }

  scrollToSection(section: string) {
    const el = document.getElementById(section) as HTMLElement;
    const main = document.getElementById('section-content') as HTMLElement;
    main.scrollTo({
      top: el.offsetTop,
      behavior: 'smooth'
    });
  }

  getFormattedDate(date?: any, format?: string) {
    const moment_date = date ? moment(date) : moment();
    return moment_date.format(format ? format : 'DD-MM-YYYY');
  }
  
  getSidebarListMaxIndex(): Observable<number> {
    return this.sidebarListIndex.asObservable();
  }
  getActiveListIndex(): Observable<any> {
    return this.activeListIndex.asObservable();
  }
  setActiveListIndex(index: number) {
    this.activeListIndex.next(index);
  }
  // set & get previous active list item
  getPreviousIndex(): Observable<number> {
    return this.previousIndex.asObservable();
  }
  setPreviousIndex(index: number) {
    this.previousIndex.next(index);
  }

  customFieldValidator(regExp: RegExp, errorMsg?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = regExp.test(control.value);
      if (control.value) {
        return isValid ? null : {
          customFieldValidationError: errorMsg ? errorMsg : 'Please enter valid input',
        };
      }
      return null
    };
  }

  getBadgeName(name: string) {
    var acronym = name?.match(/\b(\w)/g)?.join('');
    var output = acronym?.substr(0, 1);
    return output?.toUpperCase();
  }
  snackNotification(action: any, content: any) {
    this._snackBar.openFromComponent(SnackNotificationComponent, {
      data: {
        action: action,
        content: content
      },
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
  }

  splitImagesFromFiles(filesArray: any[]) {
    filesArray?.forEach(async (file: any) => {
      if (file.file_name.includes(".pdf") || file.file_name.includes(".xlsx") || file.file_name.includes(".docx")) {
        file['isDoc'] = true;
      }
    });
    const editedArray = filesArray?.map((preview: any) => { return { image: preview.isDoc ? 'assets/icons/file-icon.svg' : preview.image_url, thumbImage: preview.image_url ?? preview.thumbImage, file_name: preview.file_name, isDoc: preview.isDoc ? true : false, id: preview.id } });
    return editedArray;
  }
 }

