<ng-container *ngIf="imgCollection.length > 0">
    <div class="d-flex align-items-center">
        <!-- <div class="d-flex align-items-center file-slider-arrow" *ngIf="leftArrow">
            <ul class="ripple-icons-list w-5 d-flex justify-content-start position-relative right-10">
                <li  (click)="moveLeft()">
                    <img class="previous-arrow" src="assets/icons/slide-next-arrow-icon.svg">
                </li>
            </ul>
        </div> -->
        <div class="file-preview-container" #myContainerRef>
            <div class="pdf-container my-1 gap-10 d-flex align-items-center">
                <ng-container *ngFor="let img of imgCollection; let i = index">
                    <!-- [ngClass]="{'file-source': img.isDoc}" -->
                    <div class="file-image click-enabled position-relative" (click)="openFilePreview(img, i)" #imageWidth>
                        <div class="position-absolute file-type-indicator" *ngIf="img.isDoc">
                            <span class="upper-indicator"></span>
                            <span class="lower-indicator"></span>
                        </div>
                        <img class="click-enabled" [src]="img.image ?? img.image_url" [ngClass]="{'doc-preview': img.isDoc, 'img-preview': !img.isDoc}">
                        <div class="delete-icon click-enabled" (click)="$event.stopPropagation(); fileDelete(img.id, i)">
                            <ng-container *ngIf="isDeleteable">
                                <!-- <wz-icon [name]="'delete'" [iconClass]="'icon-18'"></wz-icon> -->
                                <img src="assets/icons/flat-delete-icon.svg">
                            </ng-container>
                        </div>
                        <div class="file-name-text position-absolute" *ngIf="img.isDoc">
                            <span class="">{{ img.file_name }}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- <div class="d-flex align-items-center file-slider-arrow" *ngIf="rightArrow">
            <ul class="ripple-icons-list w-5 d-flex justify-content-end position-relative left-10">
                <li (click)="moveRight()">
                    <img src="assets/icons/slide-next-arrow-icon.svg">
                </li>
            </ul>
        </div> -->
    </div>
</ng-container>