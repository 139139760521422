<div [@flyInOut]="action">
    <ng-container *ngFor="let doc of docs; let i = index">
      <ng-container *ngIf="activeIndex === i">
        <ng-container *ngIf="doc.isDoc; else imageTemplate">
          <ngx-doc-viewer [url]="doc.thumbImage" [viewer]="getViewerType(doc)" style="width: 100%; height: 96vh;">
          </ngx-doc-viewer>
        </ng-container>
        <ng-template #imageTemplate>
          <div class="image-preview-container">
            <img class="image-preview" [src]="doc?.image ? doc?.image : doc?.file_name">
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
  
  <div class="left-toggle click-enabled" (click)="updateActiveIndex('previous')" *ngIf="activeIndex > 0">
    <ul class="ripple-icons-list w-5 d-flex justify-content-start position-relative right-10">
        <li>
            <img class="previous-arrow" src="assets/icons/slide-next-arrow-icon.svg">
        </li>
    </ul>
  </div>
  
  <div class="right-toggle click-enabled" (click)="updateActiveIndex('next')" *ngIf="activeIndex != docs.length - 1">
    <ul class="ripple-icons-list w-5 d-flex justify-content-end position-relative left-10">
        <li>
            <img src="assets/icons/slide-next-arrow-icon.svg">
        </li>
    </ul>
  </div>
  
  <div class="close-icon click-enabled" (click)="closePopup()">
    <img src="assets/icons/gray-close-icon.svg">
  </div>