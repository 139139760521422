import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilePreviewPopupComponent } from '../file-preview-popup/file-preview-popup.component';

@Component({
  selector: 'app-file-preview-slider',
  templateUrl: './file-preview-slider.component.html',
  styleUrl: './file-preview-slider.component.scss'
})
export class FilePreviewSliderComponent {
  @Input() imgCollection!: any;
  @Input() isDeleteable: boolean = false;
  @Output() deleteFile = new EventEmitter();
  @ViewChild('myContainerRef') myContainerRef!: ElementRef<HTMLElement>;
  @ViewChild('imageWidth', { static: true }) imageWidth!: ElementRef;
  imageWidthCalculation: number = 866;
  containerWidth !: number;
  offset = 0;
  maxOffset!: number;
  screenWidth !: number;
  moveCount: number = 0;
  count: number = 0;
  rightArrow: boolean = true;
  leftArrow: boolean = false;
  maxScroll: number = 0; 
  cardWidthCalculation: number = 120;
  clickCount:number = 0
  constructor(
    private _dialog: MatDialog,
  ) {}

  ngAfterViewInit(): void {
    this.getContainerWidth();
    this.calculateMaxScroll()
  }

  ngOnInit(): void {
    // this.downloadImage = URL.createObjectURL(imageBlob); 
    const reader = new FileReader();

    reader.onload = (e: any) => {
      // This function will be called when the file is successfully loaded
      const fileContent = e.target.result; // The file content as a string or ArrayBuffer
    };
  }

  getContainerWidth() {
    const divElement = this.myContainerRef?.nativeElement as HTMLDivElement;
    const sliderContainerWidth = divElement.offsetWidth;
    this.screenWidth = sliderContainerWidth;
    const cardWidth = 120; 
    const gap = 10; 
    const totalCardWidth = this.imgCollection.length * (cardWidth + gap);
     if (sliderContainerWidth >= totalCardWidth - 10) {
       this.rightArrow = false;
     } else {
       this.rightArrow = true
   }
  }

  calculateMaxScroll() {
    const cardWidthPlusMargin = this.imgCollection.length * 10;
    const totalContentWidth = (this.cardWidthCalculation  * this.imgCollection.length) + cardWidthPlusMargin;
    this.maxScroll = Math.max(totalContentWidth - this.screenWidth, 0); 
  }

  openFilePreview(file: any, index: any) {
    this._dialog.open(FilePreviewPopupComponent, {
      width: '93vw',
      panelClass: "file-preview-popup",
      backdropClass: "file-popup-background",
      data : {
        docData: {
          files: this.imgCollection,
          index: index
        }
      }
    }).afterClosed().subscribe((res: any) => {
      if(res) {}
    });
  }

  moveLeft(): void {
    this.rightArrow = true
    this.clickCount -= 1
     if (this.myContainerRef.nativeElement.scrollLeft > 0) {
      this.myContainerRef.nativeElement.scrollLeft -= 200;
     }
     if(this.clickCount === 0) {
      this.leftArrow = false
     }  
  }

  moveRight(): void {
    this.leftArrow = true
     if (this.myContainerRef.nativeElement.scrollLeft <= this.maxScroll - 10) {
      this.myContainerRef.nativeElement.scrollLeft += 200;
       this.clickCount += 1;
     } else {
       this.rightArrow = false
     }
  }

  slideTo(offset: number): void {
    this.offset = offset;
  }

  fileDelete(id: any, i: any) {
    this.deleteFile.emit({id: id, index: i});
  }
}
